import {
	IThread,
	ContextType,
	ThreadStatus
} from 'services/api/threads/threadsServiceTypes';
import {
	IdField,
	Entity,
	EntityField,
	MetadataAction,
	Metadata
} from 'app-types';
import { PagedRequest, ResultCollection } from 'services/api/apiTypes';
import {
	Port,
	CompanyAgentAutocompleteOption
} from 'services/api/ports/portsServiceTypes';
import { CustodyAgentType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import {
	SearchedCompany,
	Company,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import {
	OperationCode,
	OperationTypeCode
} from 'services/api/operations/operationsServiceTypes';
import {
	PortCall,
	ExpandPortJobType
} from '../portCalls/portCallsServiceTypes';
import { FinanceStatus } from '../finance/financeServiceTypes';
import { CompanyDraft } from 'store/drafts/draftsState';
import { PortJobStatus } from 'store/portJobs/constants';
import { MainCommodityCode } from '../commodities/commoditiesServiceTypes';
import {
	Attachment,
	MessageEventAssign
} from '../messages/messagesServiceTypes';
import { RichTextValue } from 'components/RichText/RichTextTypes';
import { FundingRequest } from '../financeFundings/financeFundingsServiceTypes';

export interface PortJobStatusEntity {
	code: PortJobStatusLabel;
	name: string;
}

export enum PortJobStatusLabel {
	AWAITING_CONFIRMATION = 'Awaiting confirmation',
	PROCESS_PENDING = 'Process pending',
	AWAITING_APPOINTMENT = 'Appointment pending',
	AWAITING_ACCEPTANCE = 'Appointment acceptance pending',
	AWAITING_INVITATION = 'Invitation pending',
	AWAITING_HUB_REVIEW = 'Awaiting hub review',
	PDA_REQUESTED = 'PDA requested',
	ACCEPTANCE_PENDING = 'Acceptance pending',
	PDA_PENDING = 'PDA pending',
	PDA_APPROVAL_PENDING = 'PDA approval pending',
	PDA_APPROVED = 'PDA approved',
	PDA_VERIFICATION_PENDING = 'PDA verification pending',
	PDA_QUERIED = 'PDA queried',
	PDA_QUERIED_HUB = 'PDA queried (Hub)',
	PDA_QUERIED_LPA = 'PDA queried (LPA)',
	PDA_QUERIED_HUB_LPA = 'PDA queried (Hub and LPA)',
	JOB_IN_PROGRESS = 'Job in progress',
	CE_PENDING = 'CE pending',
	CE_QUERIED = 'CE queried',
	CE_QUERIED_HUB = 'CE queried (Hub)',
	CE_QUERIED_LPA = 'CE queried (LPA)',
	CE_QUERIED_HUB_LPA = 'CE queried (Hub and LPA)',
	CE_APPROVAL_PENDING = 'CE approval pending',
	CE_APPROVED = 'CE approved',
	CE_VERIFICATION_PENDING = 'CE verification pending',
	DA_PENDING = 'DA pending',
	DA_QUERIED = 'DA queried',
	DA_QUERIED_HUB = 'DA queried (Hub)',
	DA_QUERIED_LPA = 'DA queried (LPA)',
	DA_QUERIED_HUB_LPA = 'DA queried (Hub and LPA)',
	DA_APPROVAL_PENDING = 'DA approval pending',
	DA_APPROVED = 'DA approved',
	DA_VERIFICATION_PENDING = 'DA verification pending',
	DA_SETTLEMENT = 'DA Settlement',
	LPA_SETTLEMENT = 'LPA Settlement',
	DA_OUT_POSTING = 'DA Out Posting',
	HUB_SETTLEMENT = 'HUB Settlement',
	COMPLETE = 'Complete',
	CLOSED = 'Closed',
	CANCELLED = 'Cancelled'
}

export enum ClassificationCode {
	H = 'H',
	L = 'L',
	C = 'C'
}

export enum PortJobOperationMetadataActionCode {
	EDIT = 'edit',
	DELETE = 'delete'
}

export interface Classification {
	code: ClassificationCode;
	name: string;
}

export interface CompanyDraftResponse {
	id: string;
	companyName: string;
	companyOrganizationTypeCode: OrganisationType;
	isDraft: boolean;
	portJobId?: string;
	companyId: string;
	details?: CompanyAgentAutocompleteOption;
}

export interface AppointmentAttachment {
	downloadLink: string;
	fileName: string;
	documentId: string;
}

export interface PortJob {
	linkedJobs: string[];
	linkedPortJobDetails: LinkedPortJobs[];
	id: string;
	status: PortJobStatus;
	code: string;
	companyName: string;
	jobType: string;
	classification: Classification;
	financeStatus: FinanceStatus;
	hubPrincipalCompany: Commodity | null;
	appointerCompany: Commodity;
	performingAgentCompany: Commodity;
	controllingAgentCompany?: Commodity;
	nominatorCompany: Commodity | null;
	agentType: string;
	agentRole: string;
	customerReference: string;
	contact1: PortJobContact;
	contact2: PortJobContact;
	nominatorContact1: string;
	nominatorContact2: string;
	instructionToISS: string;
	instructionToLPA: string;
	operations: PortJobOperationTypes[];
	payingPartyCompany: Commodity;
	jobEstimateDaInUsd: string;
	jobEstimateDaOutUsd: string;
	updatedOnUtc: string;
	updatedOn: string;
	createdOn: string;
	portJobCompanies: CompanyDraftResponse[];
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
	canView?: boolean;
	concurrencyToken: string;
	appointmentAttachments: AppointmentAttachment[];
	isCancelled: boolean;
	isAccepted: boolean;
	metadata: Metadata<PortJobMetadataActionCode>;
	defaultMessagingGroup: Entity;
	daAgeing: string;
	hubPrincipalMailboxId?: string;
	isAgentsSwapDisabled: boolean;
	isMutipleLinkedLeadNominationJobs?: boolean;
	isAppointmentlinkedToNomination?: boolean;
	parentPortCallId?: string;
	isDraftAppointer: boolean;
	financeEnabled?: boolean;
	savings?: string;
	savingsEnabled?: boolean;
	daAssigned: Entity;
	daAssignedGroup: AssignedGroupName[];
	portJobDAs: PortJobDAs[];
	isExtendedMessaging: boolean;
	showAll: boolean;
	isAppointerNonIssHubPrincipal: boolean;
	isIntegrationJob: boolean;
	isCloneAllowed?: boolean;
	isOperationsDisabled: boolean;
}

export interface AssignedGroupName {
	id: string;
	name: string;
}

export interface PortJobDAs {
	id: string;
	name: string;
}

export interface LinkedPortJobs {
	hasPermission: boolean;
	isLeadNomination: boolean;
	portCallId: string;
	portJobCode: string;
}

export interface Commodity {
	id: string;
	name: string;
}

export interface MainCommodity extends Commodity {
	id: MainCommodityCode;
}

export interface PortJobCargoLineBase {
	positionNumber: number;
	referenceNumber?: string;
	principalVoyageReference?: string;
	chartererCompany?: SearchedCompany;
	charterPartyDate?: string;
	mainCommodity: MainCommodity;
	commodity?: Commodity;
	commodityQuantity?: string;
	splitIndex?: number;
	commodityUnitOfMeasurementCode?: string;
	terms?: string;
	dischargeCountryName?: string;
	id: string;
	isChildCargo: boolean;
	parentCargoId?: string;
	isLinkedCargo?: boolean;
}

export interface PortJobCargoLineLoad extends PortJobCargoLineBase {
	loadingFromVessel: Entity;
	shipperCompany?: Entity;
	laycanFromPlt?: string;
	laycanToPlt?: string;
	toOrders?: boolean;
	dischargePort?: Port;
	dischargePortIsUnknown: boolean;
	dischargeCountryCode?: string;
}

export interface PortJobCargoLineDischarge extends PortJobCargoLineBase {
	receiverCompany?: Entity;
	loadPort?: Port;
	dischargingToVessel?: Entity;
}

export type PortJobCargoLine = Partial<
	PortJobCargoLineLoad & PortJobCargoLineDischarge
>;

/**
 * TODO
 * UI interface, consider to remove from here
 */
export interface PortJobCargoLineWithCountry extends PortJobCargoLineLoad {
	dischargeCountryName: string;
}

interface PortJobContact {
	name: string;
	id: string;
	phone: string;
}

export interface PortJobOperation {
	id: string;
	code: OperationTypeCode;
	operationType: OperationTypeCode;
	name: string;
	portJobOperationId: string;
	hasRecordedEvents?: boolean;
	portJobCompanies?: CompanyDraftResponse[];
	concurrencyToken: number;
	metadata: Metadata<PortJobOperationMetadataActionCode>;
	isLinkedOperation?: boolean;
}

export interface LoadPortJobOperation extends PortJobOperation {
	charterers: Charterer[];
	cargoes: PortJobCargoLineLoad[];
}

export interface DischargePortJobOperation extends PortJobOperation {
	charterers: Charterer[];
	cargoes: PortJobCargoLineDischarge[];
}

export interface GenericPortJobOperation extends PortJobOperation {
	details?: string;
}

export interface SparesAndDeliveryDocumentsOperation
	extends GenericPortJobOperation {
	number?: number;
}

export interface CanalTransitPortJobOperation extends GenericPortJobOperation {
	vesselCondition: VesselCondition;
}

export interface CrewWageDisbursementPortJobOperation
	extends GenericPortJobOperation {
	numberOfCrew?: number;
	totalAmounts: PortJobOperationTotalAmount[];
}

export interface CrewChangePortJobOperation extends GenericPortJobOperation {
	onSigners?: number;
	offSigners?: number;
	immigrationAssistances?: number;
	lettersOfInvitation?: number;
	okToBoardLetters?: number;
	visaAssistances?: number;
	customerClearences?: number;
	shorePasses?: number;
	meetAndGreets?: number;
	lostLuggageAssistances?: number;
}

export interface CashToMasterPortJobOperation extends GenericPortJobOperation {
	masterFullName: string;
	totalAmounts: PortJobOperationTotalAmount[];
}

interface BunkeringPortJobOperationBase {
	details: string;
	bunkeringOperationGrades: BunkeringOperationGrade[];
}

export interface BunkeringPortJobOperation
	extends PortJobOperation,
		BunkeringPortJobOperationBase {}

export type PortJobOperationTypes =
	| LoadPortJobOperation
	| GenericPortJobOperation
	| CrewChangePortJobOperation
	| CanalTransitPortJobOperation
	| CrewWageDisbursementPortJobOperation
	| BunkeringPortJobOperation;

export interface Charterer {
	chartererCompany: Commodity;
	timeBar: TimeBar;
	maxPortCost: MaxPortCost;
	isOtherRebillablesApplied: boolean;
	costSettlementIsDirect: boolean;
	isWorldScaleServicesApplied: boolean;
	operationalInstructions: string;
	financialInstructions: string;
	id: string;
}

export interface EmptyCharterer {
	chartererCompany: Commodity;
	id: string;
}

export type ChartererUnion = EmptyCharterer | Charterer;

export interface TimeBar {
	applies: boolean;
	days: number;
}

export interface MaxPortCost {
	applies: boolean;
	amount: number;
	currencyCode: string;
}

/**
 * Single Job
 * Same structure that API Call
 */
export interface RetrieveJobParams {
	jobCode: string;
	portCallId: string;
	expand?: ExpandPortJobType;
	isSilent?: boolean;
	skipWarn?: boolean;
}

export interface RetrieveOperationParams {
	jobCode: string;
	portCallId: string;
	operationId: string;
}

export interface EditOperationActionParams {
	portCallId: string;
	jobCode: string;
	operationId: string;
}

export interface EditPortJobActionParams {
	portCallId: string;
	jobCode: string;
}

export interface AddAppointmentFromNominationActionParams {
	portCallId: string;
	jobCode: string;
}

/**
 * Threads from a job
 */
export interface PortJobThread extends IThread {
	jobCodes: Array<PortJob['code']>;
	context: ContextType;
}

export interface RetrieveJobThreadsRequest
	extends PagedRequest,
		Partial<RetrieveJobParams> {
	statuses?: ThreadStatus[];
	onlyUnread?: boolean;
}

export interface RetrieveJobThreadsResponse
	extends ResultCollection<PortJobThread> {}

export interface ConfirmPortJobParams {
	portCallId: string;
	jobCode: string;
	modalId: string;
	concurrencyToken: number;
}

export type ConfirmPortJobPayload = Pick<
	ConfirmPortJobParams,
	'portCallId' | 'jobCode' | 'modalId'
>;

// ################### ADD PORT JOB

/** @see https://dapperdox.pphoenix.co.uk/portcalls-api/reference/portcalls-api/portcalls-post */
export type PortJobAgencyTypes =
	| 'Owners'
	| 'Charterers'
	| 'CharterersProvidedCompetitive';

export type PortJobAgencyRoles =
	| 'FullAgent'
	| 'InboundAgent'
	| 'OutboundAgent'
	| 'HusbandryAgent';

interface AddPortJobCargoLine {
	chartererCompany?: EntityField;
	charterPartyDate?: string;
	cargoReferenceNumber?: string;
	principalVoyageRef?: string;
	mainCommodity: IdField;
	commodity?: EntityField;
	commodityQuantity?: number;
	commodityUnitOfMeasurementCode?: string;
	terms?: string;
}

export interface AddPortJobCargoLineLoad extends AddPortJobCargoLine {
	shipperCompany?: EntityField;
	laycanFromPlt?: string;
	laycanToPlt?: string;
	dischargePortIsUnknown?: boolean;
	dischargePort?: EntityField;
	dischargeCountryCode?: string;
	loadingFromVessel?: EntityField;
	id?: string;
}

export interface AddPortJobCargoLineDischarge extends AddPortJobCargoLine {
	receiverCompany?: EntityField;
	loadPort?: EntityField;
	dischargingToVessel?: EntityField;
	id?: string;
}

export interface AddPortJobCharterer {
	chartererCompany: EntityField;
	timeBar: {
		isApplied: boolean;
		days: number;
	};
	maxPortCost: {
		isApplied: boolean;
		amount: number;
		currencyCode: string;
	};
	isOtherRebillablesApplied: boolean;
	isWorldScaleServicesApplied: boolean;
	costSettlementIsDirect?: boolean | null;
	operationalInstructions: string;
	financialInstructions: string;
	id?: string;
}

interface AddPortJobOperation {
	code: OperationCode;
	operationType: OperationTypeCode;
	portJobCompanies?: CompanyDraft[];
}

export interface AddPortJobLoadOperation extends AddPortJobOperation {
	cargoes: AddPortJobCargoLineLoad[];
	charterers: AddPortJobCharterer[];
}

export interface AddPortJobDischargeOperation extends AddPortJobOperation {
	cargoes: AddPortJobCargoLineDischarge[];
	charterers: AddPortJobCharterer[];
}

export interface AddPortJobGenericOperation extends AddPortJobOperation {
	details: string;
}

interface AddPortJobCanalTransitOperation extends AddPortJobOperation {
	details: string;
	vesselCondition: VesselCondition;
}

export interface BunkeringOperationGrade {
	gradeId: string;
	quantity: number;
	unitOfMeasurementCode: string;
	id: string;
}

export interface AddPortJobBunkeringOperation
	extends AddPortJobOperation,
		BunkeringPortJobOperationBase {}

export interface AddPortJobCrewChangeOperation extends AddPortJobOperation {
	onSigners?: number;
	offSigners?: number;
	immigrationAssistances?: number;
	lettersOfInvitation?: number;
	okToBoardLetters?: number;
	visaAssistances?: number;
	customerClearences?: number;
	shorePasses?: number;
	meetAndGreets?: number;
	lostLuggageAssistances?: number;
	details?: string;
}

export interface PortJobOperationTotalAmount {
	id?: string;
	amount: string;
	currencyCode: string;
}

interface AddPortJobCashToMasterOperation extends AddPortJobOperation {
	masterFullName: string;
	totalAmounts: PortJobOperationTotalAmount[];
	details?: string;
}

export enum VesselCondition {
	LADEN = 'Laden',
	BALLAST = 'Ballast'
}

interface AddPortJobCanalTransit extends AddPortJobOperation {
	vesselCondition: VesselCondition;
	details?: string;
}

export interface AddPortJobCrewWageDisbursement extends AddPortJobOperation {
	details?: string;
	numberOfCrew?: string;
	totalAmounts: PortJobOperationTotalAmount[];
}

export interface AddSparesAndDocumentDelivery extends AddPortJobOperation {
	details: string;
	number: number;
}

export type AddPortJobOperationTypes =
	| AddPortJobGenericOperation
	| AddPortJobCanalTransitOperation
	| AddPortJobLoadOperation
	| AddPortJobDischargeOperation
	| AddPortJobCashToMasterOperation
	| AddPortJobCanalTransit
	| AddPortJobCrewWageDisbursement
	| AddPortJobCrewChangeOperation
	| AddPortJobBunkeringOperation
	| AddSparesAndDocumentDelivery;

export interface PortJobRequestPayload {
	jobType: string | null;
	hubPrincipalCompany: IdField | null;
	appointerCompany: EntityField | null;
	performingAgentCompany: EntityField;
	payingPartyCompany: EntityField | undefined;
	controllingAgentCompany?: EntityField;
	nominatorCompany: EntityField | null;
	agentType: PortJobAgencyTypes;
	agentRole?: PortJobAgencyRoles;
	customerReference?: string;
	contact1?: EntityField | { id: null; name: null };
	contact2?: EntityField;
	instructionToISS: string;
	instructionToLPA: string;
	isShowAllChecked: boolean;
}

export interface UpdatePortJobRequestPayload extends PortJobRequestPayload {
	concurrencyToken: string;
	portJobCompanies: CompanyDraft[];
}

export interface AddPortJobRequestPayload extends PortJobRequestPayload {
	operations: AddPortJobOperationTypes[];
	portJobCompanies: CompanyDraft[];
}

export interface AppointAgentToPortParams {
	code: keyof typeof CustodyAgentType;
	name: CustodyAgentType;
	portCallId: PortCall['id'];
	jobCode: PortJob['code'];
	modalId: string;
	serviceVendors?: AppointDataSelectedVendor[];
	concurrencyToken: number;
	isGateway: boolean;
}

export type AppointAgentToPortPayload = Pick<
	AppointAgentToPortParams,
	'code' | 'name' | 'portCallId' | 'jobCode' | 'modalId' | 'serviceVendors'
>;

export interface InviteAgentToPortJobParams {
	portCallId: PortCall['id'];
	jobCode: PortJob['code'];
	modalId: string;
	concurrencyToken: number;
}

export type InviteAgentToPortJobPayload = Pick<
	InviteAgentToPortJobParams,
	'portCallId' | 'jobCode' | 'modalId'
>;

// ADD PORT JOB

/**
 * The response after creating a new PortJob
 * id & code are returning from API
 * portCallId it's manually added on the Sagas in order to perform the redirection
 */
export interface AddPortJobResponse {
	// id of the new PortJob
	id: string;
	// PortJob code
	code: string;
	// PortCall id which the PortJob belongs to
	portCallId: string;
}

export interface ConfirmPortJobResponse {
	status: string;
}

export type DeletePortJobResponse = void;
export interface CheckPortJobPermissionsResponse {
	hasVesselMapping: boolean;
	hasPermission: boolean;
}

// GET PORT JOB SUMMARY

interface IdName {
	id: string;
	name: string;
}

export interface PortJobSummaryResponse {
	appointerCompany: IdName;
	jobType: string;
	jobStatus: PortJobStatusCode;
	performingAgentCompany: IdName;
	portCallSummary: {
		eta: string;
		ets: string | null;
		port: IdName;
		vessel: IdName;
	};
}

export interface PortJobBaseRequest {
	portCallId: string;
	jobCode: string;
}

export interface AppointDataVendor {
	id: string;
	name: string;
	email: string;
}

export interface AppointDataSelectedVendor {
	serviceCode: string;
	vendorId: string;
}

export interface AppointDataService {
	serviceCode: string;
	serviceName: string;
	availableVendors: AppointDataVendor[];
	draftVendors: AppointDataVendor[];
}

export interface AppointData {
	serviceVendors: AppointDataService[];
}

export interface AcceptanceDataVendor {
	name: string;
	email: string;
	instructions: string;
	serviceNames: string[];
}

export interface AcceptanceData {
	vendors: AcceptanceDataVendor[];
	appointmentAttachments: AppointmentAttachment[];
	appointmentAcceptanceInstruction?: string;
}

export type PortJobAvailableGroup = Entity<string>;

export interface PortJobSummaryRequest extends PortJobBaseRequest {
	isSilent?: boolean;
}

export interface ExtendedMessagingRequest extends PortJobBaseRequest {
	mailType: string;
	serviceVendors?: AppointDataSelectedVendor[];
	concurrencyToken?: number;
}

export interface InviteExtendedMessagingRequest extends PortJobBaseRequest {
	mailType: string;
}

export interface PrefundingExtendedMessagingData extends PortJobBaseRequest {
	mailType: string;
	selectedStatus: string;
	sendPrefunding: boolean;
	portName: string;
	vesselName: string;
	fundingRequestUpdateFundingRequestList: FundingRequest[];
	documentId?: string;
}

// CUSTODY ROLES
export interface CustodyRole {
	code: string;
	isAllowed: boolean;
	name: CustodyAgentType;
}

export interface PortJobCustodyRolesRequest extends PortJobBaseRequest {}
export interface PortJobCustodyRolesResponse
	extends ResultCollection<CustodyRole> {}

// ADD OPERATION TO EXISTING JOB
export interface AddPortJobOperationRequest {
	portCallId: string;
	jobCode: string;
	payload: AddPortJobOperationTypes;
}

// Edit OPERATION
export interface EditPortJobOperationRequest {
	portCallId: string;
	jobCode: string;
	operationId: string;
	payload: AddPortJobOperationTypes;
}

// Edit PortJob
export interface UpdatePortJobRequest {
	portCallId: string;
	jobCode: string;
	payload: UpdatePortJobRequestPayload;
}

export interface DeletePortJobRequest {
	portCallId: string;
	jobCode: string;
	concurrencyToken: number;
}

export type DeletePortJobPayload = Pick<
	DeletePortJobRequest,
	'portCallId' | 'jobCode'
>;

export interface AddPortJobOperationResponse {
	id: string;
	name: string;
}

export interface EditPortJobOperationResponse {
	id: string;
	name: string;
}

export interface EditPortJobResponse {
	portJob: PortJob;
}

// Delete OPERATION
export interface DeletePortJobOperationRequest {
	portCallId: string;
	portJobCode: string;
	portJobOperationId: string;
	concurrencyToken: number;
	isMutipleLinkedLeadNominationJobs?: boolean;
	hasLinkedCargo?: boolean;
}

export type DeletePortJobOperationPayload = Pick<
	DeletePortJobOperationRequest,
	| 'portCallId'
	| 'portJobCode'
	| 'portJobOperationId'
	| 'isMutipleLinkedLeadNominationJobs'
	| 'hasLinkedCargo'
>;

export interface PortJobStatusUpdateResponse {
	status: string;
}

export type RetrievePortJobStatusesResponse = PortJobStatusEntity[];

export interface CheckPortJobPermissionsRequest {
	portId: string;
	vesselId: string;
	mainPrincipalId: string;
}

export interface CancelPortJobParams {
	cancelReason: PortJobCancelReason;
	modalId: string;
}

export interface CancelPortJobBasicRequest {
	portCallId: string;
	portJobCode: string;
	isCancelled: boolean;
	concurrencyToken: number;
}

export interface CancelPortJobRequest
	extends CancelPortJobParams,
		CancelPortJobBasicRequest {}

export type UncancelPortJobRequest = CancelPortJobBasicRequest;

export interface ChangeCustodyRoleParams {
	modalId: string;
}

export interface ChangeCustodyRoleRequest extends ChangeCustodyRoleParams {
	portCallId: string;
	portJobCode: string;
	concurrencyToken: number;
}

export interface UpdateSavingsParams {
	savings: string;
}

export interface UpdateSavingsRequest extends UpdateSavingsParams {
	portCallId: string;
	portJobCode: string;
}

export interface RequestPDARequest {
	portCallId: string;
	portJobCode: string;
}

export interface MovetoPDAPendingRequest {
	portcallId: string;
	jobCode: string;
	concurrencyToken: number;
	moveBackToJobStatus: string;
}

export interface MovetoCEPendingRequest {
	portcallId: string;
	jobCode: string;
	concurrencyToken: number;
}

export interface MoveBackToJobStatusActionRequest {
	moveBackToJobStatus: string;
}

export type ReOpenJob = MovetoPDAPendingRequest;

export interface RetrievePortJobMetadataRequest {
	portCallId: string;
	portJobCode: string;
}

export enum PortJobTypeCode {
	APPOINTMENT = 'Appointment',
	NOMINATION = 'Nomination',
	CHARTERER_OVERSIGHT = 'ChartererOversight'
}

// TODO - refactor to enum and update possible values as those can be outdated
type PortJobStatusCode =
	| 'New'
	| 'AwaitingConfirmation'
	| 'AcceptancePending'
	| 'PDA_PENDING'
	| 'JobExecution'
	| 'DispositionPending'
	| 'LPA_SETTLEMENTS'
	| 'DA_OUT_POSTINGS'
	| 'HUB_SETTLEMENTS'
	| 'DA_SETTLEMENTS'
	| 'CE'
	| 'DA';

export type AppointerType = Company;

export interface RetrieveAppointerRequest {
	mainPrincipalId: string;
	actionType: PermissionCode;
}

export interface SetDefaultMessagingGroupRequest extends PortJobBaseRequest {
	portJobId: string;
	group: Entity;
	concurrencyToken: number;
}

export interface SetDefaultMessagingGroupResponse {
	concurrencyToken: string;
}

// we don't get enum type from BE,
// this was used in `sections/PortJob/CreatePortJob/Pages/Page2/PagePartials/Form/Form.tsx` to somehow match needed object
// BE should change response to the same as `PortJobType` is
export enum AgencyRoles {
	HUSBANDRY_AGENT = 'Husbandry Agent',
	FULL_AGENT = 'Full Agent',
	INBOUND_AGENT = 'Inbound Agent',
	OUTBOUND_AGENT = 'Outbound Agent'
}

export enum PortJobCancelReason {
	WrongPortSelected = 'WrongPortSelected',
	ChangeOfPort = 'ChangeOfPort',
	ApplicationIssues = 'ApplicationIssues',
	Duplicate = 'Duplicate',
	VoyageCancelled = 'VoyageCancelled',
	HighCost = 'HighCost',
	NoRepresentation = 'NoRepresentation',
	ResponseDelay = 'ResponseDelay',
	ShortNotice = 'ShortNotice',
	VisaIssues = 'VisaIssues',
	Other = 'Other'
}

export const PortJobCancelReasonMap = {
	[PortJobCancelReason.WrongPortSelected]: 'Wrong Port Selected',
	[PortJobCancelReason.ChangeOfPort]: 'Change of Port',
	[PortJobCancelReason.ApplicationIssues]: 'Application Issues',
	[PortJobCancelReason.Duplicate]: 'Duplicate',
	[PortJobCancelReason.VoyageCancelled]: 'Voyage Cancelled',
	[PortJobCancelReason.HighCost]: 'High Cost',
	[PortJobCancelReason.NoRepresentation]: 'No Representation',
	[PortJobCancelReason.ResponseDelay]: 'Response Delay',
	[PortJobCancelReason.ShortNotice]: 'Short Notice',
	[PortJobCancelReason.VisaIssues]: 'Visa Issues',
	[PortJobCancelReason.Other]: 'Other (Sanctions/Legal/Restrictions)'
};

export enum PortJobMetadataActionCode {
	SWAP_AGENTS = 'swapPerformingControllingAgents',
	CANCEL = 'cancelJob',
	MOVE_TO_CE_PENDING = 'moveToCEPending',
	RE_OPEN_JOB = 'reopenTheJob'
}

export type PortJobMetadataAction = MetadataAction<PortJobMetadataActionCode>;

export enum PortJobStatusComparisonType {
	SAME_OR_BEFORE = 'SAME_OR_BEFORE',
	SAME_OR_AFTER = 'SAME_OR_AFTER'
}

interface AssignedInbox {
	id: string;
}

interface EmailRecipents {
	companyId: string;
	email: string;
	isContextualised: boolean;
	organisationTypes: [];
}

export interface ExtendedMessage {
	attachments: Attachment[];
	cc: [];
	content: string;
	contextId: string;
	emailType: string;
	from: EmailRecipents;
	to: EmailRecipents[];
	isQuickResponse: boolean;
	isSplitThreadSubscribedByPrincipal: boolean;
	jobCodes: string[];
	messageType: number;
	mustStartNewThread: boolean;
	replyTo: [];
	skipEmailSending: boolean;
	subject: string;
	threadId: string;
	unsupportedAttachments: [];
	updateThreadStatus: ThreadStatus;
	editedContent?: RichTextValue;
	isContentTouched?: boolean;
	isExtendedMessageDisplay?: boolean;
	assignedGroup: MessageEventAssign;
	assignedInbox: AssignedInbox;
}

export interface ExtendedMessagingData {
	assignedGroup: MessageEventAssign;
	assignedInbox: AssignedInbox;
	contextId: string;
	isProtected: boolean;
	jobCodes: string[];
	messages: ExtendedMessage[];
}

export interface ExtendedMessagingActionRequest {
	mailType: string;
	serviceVendors?: AppointDataSelectedVendor[];
	concurrencyToken?: number;
}

export enum EmailTypes {
	JobAppointment = 'JobAppointment',
	ControllingAgentAppointment = 'ControllingAgentAppointment',
	EmailToMaster = 'EmailToMaster',
	ControllingAgentInvitation = 'ControllingAgentInvitation',
	JobInvitation = 'JobInvitation',
	DirectBillEmailToVendor = 'DirectBillEmailToVendor',
	CancelPortJob = 'CancelPortJob',
	EmailToVendorInvitation = 'EmailToVendorInvitation',
	PrefundingEmail = 'CustomerPrefunding',
	PDARequest = 'PDARequest'
}

export const EmailTypesTitle = {
	[EmailTypes.JobAppointment]: 'Performing Agent',
	[EmailTypes.ControllingAgentAppointment]: 'Controlling Agent',
	[EmailTypes.EmailToMaster]: 'Master Email',
	[EmailTypes.JobInvitation]: 'Performing Agent',
	[EmailTypes.ControllingAgentInvitation]: 'Controlling Agent',
	[EmailTypes.DirectBillEmailToVendor]: 'Vendor Email',
	[EmailTypes.CancelPortJob]: 'Cancel Agent',
	[EmailTypes.EmailToVendorInvitation]: 'Vendor Email',
	[EmailTypes.PrefundingEmail]: 'Customer Prefunding',
	[EmailTypes.PDARequest]: 'Request PDA'
};

export interface TogglePortJobStatusParams {
	portCallId: string;
	jobCode: string;
	jobStatus: string;
	concurrencyToken?: number;
}

export type TogglePortJobStatusPayload = TogglePortJobStatusParams;

export interface TogglePortJobResponse {
	status: string;
}

export interface ToggleOperationsStatusParams {
	portCallId: string;
	jobCode: string;
	isOperationsDisabled: boolean;
}

export interface ToggleOperationsResponse {
	isOperationsDisabled: boolean;
}

interface UnlinkJobsBasicRequest {
	portCallId: string;
	portJob: string;
	isMutipleLinkedLeadNominationJobs: boolean;
	linkedAppJobCode: string;
}

export interface UnlinkJobsParams {
	modalId: string;
}

export interface UnlinkJobsRequest
	extends UnlinkJobsParams,
		UnlinkJobsBasicRequest {}

export interface ClonePortJobRequestPayload {
	portCallId: IdField;
	jobCode: string;
	selectedVessel?: EntityField;
	selectedPort?: EntityField;
	newPerformAgent?: EntityField;
	newControlAgent?: EntityField;
	eTA?: string;
	eTS?: string;
	portJobAgentCompanies?: CompanyDraft[];
	isNewPortCall: boolean;
	enableIntegration: boolean;
}

export interface ClonePortJobRequest {
	portCallId: string;
	jobCode: string;
	modalId: string;
}

export interface PortJobOperationsWithCargoes extends PortJobOperation {
	cargoes?: PortJobCargoLineBase[];
}
